<template>
  <v-card
    class="rounded"
    flat
    tile
  >
    <v-list
      dense
      color="white"
    >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-virtual-scroll
          :items="items"
          height="700"
          item-height="72"
        >
          <template #default="{ item, index }">
            <v-list-item
              @click="onClick(item, index)"
            >
              <v-list-item-avatar
                size="48"
              >
                <v-avatar
                  size="48"
                  :color="item.avatar ? 'transparent' : 'primary'"
                >
                  <v-img
                    v-if="item.avatar"
                    :src="item.avatar"
                    cover
                  />
                  <v-icon
                    v-else-if="item.icon"
                    color="white"
                  >
                    {{ item.icon }}
                  </v-icon>
                  <span
                    v-else
                    class="text-uppercase white--text headline "
                  >
                    {{ item.text.substr(0, 2) }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
                <v-list-item-subtitle
                  v-if="item.message"
                  class="mt-2"
                >
                  {{ item.message }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div
                  v-if="item.newest"
                  class="co-w12 co-h12 co-round-full co-bg-red-500"
                />
                <v-list-item-action-text
                  v-if="item.hint"
                >
                  {{ item.hint }}
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  /**
   * 会话对象
   * {
   *  userId：'',
   *  text: '王五',
      avatar: 'https://oss.landcoo.com/yujian/1646868248895033344.jpg',
      type: web.comm.MessageTypes.CHAT,
      message: '学习了',
      hint: '5分钟前'
   * }
   */
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'
  import socket from '@/api/co.socket'

  export default {
    data () {
      return {
        selectedItem: 0,
        activeColor: 'grey lighten-3',
        hoverColor: 'blue-grey lighten-4',
        userInfo: {},
        items: [],
        sesParams: null,
        sesItems: [],
        cats: [{
          text: '点赞',
          icon: 'mdi-thumb-up-outline',
          type: web.comm.MessageTypes.LIKE,
          newest: 0
        }, {
          text: '评论',
          icon: 'mdi-comment-processing-outline',
          type: web.comm.MessageTypes.COMMENT,
          newest: 0
        }, {
          text: '关注',
          icon: 'mdi-account-multiple-outline',
          type: web.comm.MessageTypes.ATTENTION,
          newest: 0
        }],
        secs: []
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.configItems()
      this.configSessionParams({
        chatUserId: this.userInfo.userId
      })
      socket.main.bind({
        name: 'msg_category',
        trigger: this.receivedMsg
      })
      this.loadSessions()
    },
    destroyed () {
      socket.main.unbind('msg_category')
    },
    methods: {
      receivedMsg (msg) {
        console.log('receivedMsg msg: %o', msg)
        this.updateItem(msg)
      },
      refreshReddot () {
        let value = 0
        for (const i in this.items) {
          const item = this.items[i]
          value += item.newest
        }

        app.emit('appbar_reddot', value)
      },
      updateItem (msg) {
        if (msg.type === 'chat') {
          const index = this.getItemIndex(msg.from)
          if (index !== -1) {
            const item = this.items[index]
            item.newest += 1
            item.message = msg.content || ''
            item.hint = msg.hintTime
          }
        } else {
          let cat = null
          for (const i in this.items) {
            const item = this.items[i]
            if (item.type === msg.type) {
              cat = item
              break
            }
          }

          if (cat) {
            cat.newest += 1
          }
        }
      },
      getItemIndex (chatUser) {
        let ind = -1
        const cnt = this.items.length
        for (let i = 0; i < cnt; i++) {
          const src = this.items[i]
          if (src.userId === chatUser.userId) {
            ind = i
            break
          }
        }

        return ind
      },
      addSection (chatUser) {
        const startInd = 3
        const ind = this.getItemIndex(chatUser)
        if (ind === -1) {
          const dst = {
            userId: chatUser.userId,
            username: chatUser.username,
            text: chatUser.username,
            avatar: chatUser.avatar,
            message: '',
            hint: '',
            type: web.comm.MessageTypes.CHAT,
            newest: 0
          }

          this.items.splice(startInd, 0, dst)
          this.selectedItem = startInd
        } else {
          this.selectedItem = ind
        }
      },
      onClick (item, index) {
        item.newest = 0
        this.refreshReddot()
        socket.cacher.resetBy(item.type, item.userId || '')
        this.selectedItem = index
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      },
      configItems () {
        this.items = []
        for (const i in this.cats) {
          const src = this.cats[i]
          this.items.push({ ...src })
        }

        for (const j in this.secs) {
          const src = this.secs[j]
          this.items.push({ ...src })
        }
      },
      addSections () {
        for (const i in this.sesItems) {
          const item = this.sesItems[i]
          const chatUser = {}
          if (item.fromUserId === this.userInfo.userId) {
            chatUser.userId = item.toUserId
            chatUser.username = item.toUsername
            chatUser.avatar = item.toAvatar
          } else {
            chatUser.userId = item.fromUserId
            chatUser.username = item.fromUsername
            chatUser.avatar = item.fromAvatar
          }

          this.addSection(chatUser)
        }
      },
      updateSections () {
        // console.log('updateSections chats: %o', socket.cacher.chats)
        for (const i in this.items) {
          const item = this.items[i]
          // console.log('item.type: %o, item.userId: %o', item.type, item.userId || '')
          const last = socket.cacher.getLast(item.type, item.userId || '')
          item.newest = last.newest
          if (item.type === 'chat') {
            item.message = last.message
            item.hint = last.hint
          }
        }
      },
      configSessionParams ({
        chatUserId
      }) {
        const me = this

        const executed = function (res) {
          // console.log('sesItems: %o', me.sesItems)
          if (res.status) {
            me.addSections()
            me.updateSections()
          } // if (res.status)
        }

        this.sesParams = web.imSession.getParams({
          chatUserId: 'all',
          caches: this.sesItems,
          executed
        })
      },
      loadSessions (reload = true) {
        if (!this.sesParams) {
          return
        }
        this.sesParams.reload = reload
        api.httpx.getItems(this.sesParams)
      },
    }
  }
</script>
